import React, { useEffect, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import makeStyles from '@mui/styles/makeStyles';
import { authenticationService } from '../../../Authentication/store/services';
import { SnackbarSuccess } from '../../../../helpers/snackbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CameraTwoToneIcon from '@mui/icons-material/CameraTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { authenticationActions } from '../../../Authentication/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import { Trans, useTranslation } from 'react-i18next';
import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import ShoppingBasketTwoToneIcon from '@mui/icons-material/ShoppingBasketTwoTone';
import TransferWithinAStationTwoToneIcon from '@mui/icons-material/TransferWithinAStationTwoTone';
import UserTransferModal from './UserTransferModal';
import UserCreditModal from './UserCreditModal';
import UserMembershipModal from './UserMembershipModal';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import { isGod, isMegaAdmin } from '../../../Authentication/store/selectors';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { Link, useLocation } from 'react-router-dom';
import { getConsultantLabels } from '../../../Pro/Network/helpers/helpers';
import useDateFns from '../../../../helpers/useDateFns';
import { ListItemButton } from '@mui/material';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { useOrderModal } from './Providers/CreateOrderProvider';

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'auto',
    '& .nowrap': {
      whiteSpace: 'nowrap',
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '& .MuiListItemIcon-root': {
      minWidth: 40,
    },
    '& .MuiListItem-root': {
      padding: 0,
      '& .MuiListItemButton-root': {
        height: 60,
        '& .MuiSvgIcon-root': {
          fontSize: '1.6em',
          fill: red[900],
        },
      },
    },
    '& .MuiAvatar-colorDefault': {
      margin: 'auto',
      width: 150,
      height: 150,
      marginTop: 20,
      marginBottom: 20,
      position: 'relative',
      '& img': {
        width: 150,
        height: 150,
      },
      '& button': {
        position: 'absolute',
        top: 45,
        left: 0,
        display: 'none',
      },
      '& p': {
        position: 'absolute',
        top: 65,
        left: 65,
      },
      '&:hover': {
        '& button': {
          display: 'inline',
        },
      },
    },
  },
}));

const UserActionsCard = ({ onChange, user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const dateFns = useDateFns();
  const userIsGod = useSelector(isGod);
  const userIsMegaAdmin = useSelector(isMegaAdmin);
  const { openOrderModal } = useOrderModal();
  const { level, primary } = getConsultantLabels(user.role, user.career);
  const [isTransferModalOpen, setTransferModalOpen] = useState(false);
  const [isCreditModalOpen, setCreditModalOpen] = useState(false);
  const [isMembershipModalOpen, setMembershipModalOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const match = location.pathname.split(`/${user.id}`);
    setSelected(match[1] || '');
  }, [location.pathname]);

  const handleTakeControl = () => {
    dispatch(authenticationActions.takeControlRequest(user.id));
  };

  const handleCreditModalOpen = () => {
    setCreditModalOpen(true);
  };

  const handleTransferModalOpen = () => {
    setTransferModalOpen(true);
  };

  const handleUserTransferred = () => {
    onChange();
  };

  const handleUserCredited = () => {
    onChange();
  };

  const handleUserMembershipUpdated = () => {
    onChange();
  };

  const handleSendWelcomeEmail = () => {
    authenticationService
      .sendWelcomeEmail(user.id)
      .then(() => {
        SnackbarSuccess('Email envoyé');
      })
      .catch((e) => {
        console.log('e', e);
      });
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Avatar>
              <img alt="" src={`${user.profilePicture}`} />
            </Avatar>
            <Typography align={'center'} gutterBottom variant={'h5'}>
              {user.firstName} {user.lastName}
            </Typography>
            {!!user.company && (
              <Typography align={'center'} gutterBottom variant={'h5'}>
                {user.company}
              </Typography>
            )}
            <Typography align={'center'} variant={'h6'}>
              {t(primary).toUpperCase()}
              {level && (
                <span>
                  <br />
                  {t('level', { lvl: level })}
                </span>
              )}
            </Typography>

            <Typography align={'center'} className={'createdAt'}>
              <Trans
                i18nKey={'createdAt'}
                values={{ date: dateFns.format(user.createdAt, 'LL') }}
              >
                Created at {dateFns.format(user.createdAt, 'LL')}
              </Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h3" variant={'h6'}>
              Actions :
            </Typography>
            <List>
              <ListItem>
                <ListItemButton
                  component={Link}
                  selected={selected === ''}
                  to={`/admin/users/${user.id}`}
                >
                  <ListItemIcon>
                    <AccountCircleTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Informations générales'} />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemButton onClick={openOrderModal}>
                  <ListItemIcon>
                    <ShoppingBasketTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Créer une commande'} />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemButton
                  component={Link}
                  selected={selected === '/update'}
                  to={`/admin/users/${user.id}/update`}
                >
                  <ListItemIcon>
                    <EditTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Modifier les informations'} />
                </ListItemButton>
              </ListItem>
              <Divider />
              {userIsGod && (
                <>
                  <ListItem>
                    <ListItemButton
                      component={Link}
                      selected={selected === '/authorizations'}
                      to={`/admin/users/${user.id}/authorizations`}
                    >
                      <ListItemIcon>
                        <LockOpenTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Modifier les autorisations'} />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </>
              )}
              {userIsMegaAdmin && (
                <>
                  <ListItem>
                    <ListItemButton onClick={handleTakeControl}>
                      <ListItemIcon>
                        <CameraTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Prendre le controle" />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </>
              )}
              <ListItem>
                <ListItemButton component={'a'} href={`tel:${user.phone}`}>
                  <ListItemIcon>
                    <PhoneTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('call')} />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemButton component={'a'} href={`mailto:${user.email}`}>
                  <ListItemIcon>
                    <SendTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('sendEmail')} />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemButton
                  component={Link}
                  selected={selected === '/orders'}
                  to={`/admin/users/${user.id}/orders`}
                >
                  <ListItemIcon>
                    <ShoppingBasketTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Commandes'} />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemButton
                  component={Link}
                  selected={selected === '/shipping'}
                  to={`/admin/users/${user.id}/shipping`}
                >
                  <ListItemIcon>
                    <LocalShippingTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Adresses de livraison'} />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemButton onClick={handleCreditModalOpen}>
                  <ListItemIcon>
                    <CreditCardTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Ajouter du crédit'} />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemButton onClick={handleTransferModalOpen}>
                  <ListItemIcon>
                    <TransferWithinAStationTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Transférer'} />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemButton onClick={handleSendWelcomeEmail}>
                  <ListItemIcon>
                    <SendTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Renvoyer les identifiants par e-mail" />
                </ListItemButton>
              </ListItem>
              <Divider />
            </List>
          </Grid>
        </Grid>
      </CardContent>
      <UserTransferModal
        onSuccess={handleUserTransferred}
        open={isTransferModalOpen}
        setOpen={setTransferModalOpen}
        user={user}
      />
      <UserCreditModal
        onSuccess={handleUserCredited}
        open={isCreditModalOpen}
        setOpen={setCreditModalOpen}
        user={user}
      />
      <UserMembershipModal
        onSuccess={handleUserMembershipUpdated}
        open={isMembershipModalOpen}
        setOpen={setMembershipModalOpen}
        user={user}
      />
    </Card>
  );
};
export default UserActionsCard;
