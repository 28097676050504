import React, { useEffect, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import makeStyles from '@mui/styles/makeStyles';
import DeliveryList from '../../../Logistic/Order/components/DeliveryList';
import { userService } from '../store/services';
import { grey } from '@mui/material/colors';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
      borderBottom: `1px solid ${grey[300]}`,
      borderRadius: 0,
    },
    '& .item': {
      padding: '0px 4px',
    },
    '& .order-options': {
      '& .MuiButton-root': {
        marginRight: 8,
      },
    },
  },
}));

const UserOrdersCard = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();
  const [maximized, setMaximized] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    loadOrders();
  }, [user]);

  const loadOrders = () => {
    userService.getUserOrders(user.id).then((orders) => {
      setOrders(orders);
    });
  };

  const handleClick = (order) => {
    history.push(`/admin/accounting/order/${order.id}`);
  };

  const handleMaximize = () => {
    setMaximized(true);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justifyContent={'center'} spacing={2}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Grid
              alignItems={'center'}
              container
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography component="h3" gutterBottom variant={'h6'}>
                  Commandes :
                </Typography>
              </Grid>
              <Grid className={'orders'} item xs={12}>
                <DeliveryList
                  onClick={handleClick}
                  orders={orders.slice(0, maximized ? orders.length : 10)}
                  withoutDetails
                />
              </Grid>
              {orders.length > 10 && !maximized && (
                <Grid item xs={12}>
                  <Typography align={'center'}>
                    <Button
                      onClick={handleMaximize}
                      size={'small'}
                      variant={'outlined'}
                    >
                      Afficher toutes les commandes
                    </Button>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default UserOrdersCard;
