import React from 'react';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  isAccountant,
  isAdmin,
  isLogistic,
  isSessionControl,
  userSelector,
} from '../../../../../containers/Authentication/store/selectors';
import { Link } from '../../../../../helpers/multilingual';
import { useTranslation } from 'react-i18next';
import { authenticationActions } from '../../../../../containers/Authentication/store/actions';
import { Link as DomLink } from 'react-router-dom';
import CloseIcon from '../../../../Icons/CloseIcon';
import AdminIcon from '../../../../Icons/AdminIcon';
import CalendarIcon from '../../../../Icons/CalendarIcon';
import AccountIcon from '../../../../Icons/AccountIcon';
import SignOutIcon from '../../../../Icons/SignOutIcon';
import AccountingIcon from '../../../../Icons/AccountingIcon';
import UserMenuItem from './UserMenuItem';
import { grey } from '@mui/material/colors';

const UserMenu = () => {
  const user = useSelector(userSelector);
  const userIsAdmin = useSelector(isAdmin);
  const userIsLogistic = useSelector(isLogistic);
  const userIsAccountant = useSelector(isAccountant);
  const userIsSessionControl = useSelector(isSessionControl);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRestoreSessionControl = () => {
    setAnchorEl(null);
    dispatch(authenticationActions.restoreControlRequest());
  };

  const handleSignOut = () => {
    setAnchorEl(null);
    dispatch(authenticationActions.signOut());
  };

  return (
    <React.Fragment>
      <Button
        endIcon={
          <AccountCircleRoundedIcon sx={{ fontSize: '30px !important' }} />
        }
        onClick={handleClick}
        sx={{
          display: { md: 'inherit', xs: 'none' },
          border: `1px solid ${grey[600]}`,
          borderRadius: 5,
          height: 43.5,
        }}
        variant={'text'}
      >
        <MenuIcon sx={{ fontSize: '1rem' }} />
      </Button>
      <Menu
        MenuListProps={{
          sx: {
            //minHeight: 400,
            minWidth: 280,
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disableScrollLock={true}
        elevation={0}
        id="user-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
        sx={{
          border: 'none',
          mt: 1,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <UserMenuItem
          hidden={!userIsSessionControl}
          icon={<CloseIcon fontSize="small" />}
          onClick={handleRestoreSessionControl}
          title={'Arrêter la session'}
        />
        <UserMenuItem
          component={Link}
          hidden={user}
          icon={<AccountIcon fontSize="small" />}
          onClick={handleClose}
          title={t('signIn')}
          to={'/auth/signin'}
        />
        <UserMenuItem
          component={DomLink}
          hidden={!userIsAdmin}
          icon={<AdminIcon fontSize="small" />}
          onClick={handleClose}
          title={'Admin'}
          to={'/admin'}
        />
        <UserMenuItem
          component={DomLink}
          hidden={!userIsLogistic}
          icon={<CalendarIcon fontSize="small" />}
          onClick={handleClose}
          title={'Logistique'}
          to={'/logistic/orders/todo'}
        />
        <UserMenuItem
          component={DomLink}
          hidden={!userIsAccountant}
          icon={<AccountingIcon fontSize="small" />}
          onClick={handleClose}
          title={'Comptabilité'}
          to={'/accounting'}
        />
        <UserMenuItem
          hidden={!user}
          icon={<SignOutIcon fontSize="small" />}
          onClick={handleSignOut}
          title={t('signOut')}
        />
      </Menu>
    </React.Fragment>
  );
};
export default UserMenu;
