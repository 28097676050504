import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import OrderListItems from './OrderListItems';
import Typography from '@mui/material/Typography';
import ProductsAutocomplete from '../../../../Customer/Shop/components/Search/ProductsAutocomplete';
import { userService } from '../../store/services';
import {
  SnackbarConfirm,
  SnackbarError,
  SnackbarFormattedError,
  SnackbarSuccess,
} from '../../../../../helpers/snackbar';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import SelectValidator from '../../../../../components/Validators/SelectValidator';
import MenuItem from '@mui/material/MenuItem';
import { shopService } from '../../../../Customer/Shop/store/services';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  CUSTOMER_ROLE,
  PROSPECT_ROLE,
} from '../../../../Authentication/store/constants';

const useStyle = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    //minWidth: 400,
    '& .MuiContainer-root': {
      marginTop: 20,
      marginBottom: 20,
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
  addProductButton: {
    marginBottom: 30,
  },
}));

const AddFreeUserOrderModal = ({
  changeToPaidOrder,
  handleClose,
  onSuccess,
  open,
  user,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState({});
  const [withShipping, setWithShipping] = useState(true);
  const [shippingMethods, setShippingMethods] = useState(null);
  const [shippingMethod, setShippingMethod] = useState(null);
  const [shippingAddresses, setShippingAddresses] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);

  useEffect(() => {
    userService
      .getUserShippingAddresses(user.id)
      .then((shippingAddresses) => {
        setShippingAddresses(shippingAddresses);
        setShippingAddress(shippingAddresses[0]);
      })
      .catch((e) => SnackbarFormattedError(e.error));
  }, [user]);

  useEffect(() => {
    if (shippingAddress) {
      shopService
        .getShippingMethods(shippingAddress.country, 1)
        .then((shippingMethods) => {
          setShippingMethods(shippingMethods);
          setShippingMethod(shippingMethods[0]);
        })
        .catch((e) => SnackbarFormattedError(e.error));
    }
  }, [shippingAddress]);

  const handleSelectProduct = (product) => {
    if (!products[product.id]) {
      product.priceAndTaxDetails.final = [
        PROSPECT_ROLE,
        CUSTOMER_ROLE,
      ].includes(user.role)
        ? product.priceAndTaxDetails.base
        : product.priceAndTaxDetails.final;
      setProducts({ ...products, [product.id]: { ...product, quantity: 1 } });
    }
  };

  const handleRemoveProduct = (productId) => {
    let oldProducts = products;
    delete oldProducts[productId];
    setProducts({ ...oldProducts });
  };

  const setProductQuantity = (product, quantity) => {
    if (quantity === 0) {
      handleRemoveProduct(product.id);
      return;
    }
    setProducts({
      ...products,
      [product.id]: { ...product, quantity: quantity },
    });
  };

  const handleChangeWithShipping = (e) => {
    setWithShipping(e.target.value === 'true');
  };

  const handleChangeShippingMethod = (e) => {
    setShippingAddress(shippingMethods.find((a) => a.id === e.target.value));
  };

  const handleChangeShippingAddress = (e) => {
    setShippingAddress(shippingAddresses.find((a) => a.id === e.target.value));
  };

  const handleSubmit = () => {
    if (!shippingMethod || !shippingAddress) {
      SnackbarError(
        'Vous devez séléctionner une méthode de livraison et séléctionner une adresse',
      );
      return;
    }
    SnackbarConfirm(
      'Cette action est irréversible, confirmer la création de la commande ?',
      () => {
        setLoading(true);
        userService
          .addFreeUserOrder(
            user,
            Object.keys(products).map((productId) => ({
              id: productId,
              quantity: products[productId].quantity,
            })),
            withShipping,
            shippingMethod.id,
            shippingAddress.id,
          )
          .then((order) => {
            SnackbarSuccess('La commande a bien été créée');
            setLoading(false);
            setProducts({});
            onSuccess(order.id);
            handleClose();
          })
          .catch((e) => {
            setLoading(false);
            SnackbarFormattedError(e.error);
          });
      },
    );
  };

  return (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
    >
      <CloseIcon className={classes.closeButton} onClick={handleClose} />
      <DialogTitle id="responsive-dialog-title">
        Offrir une commande
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sx={{ textAlign: 'right' }} xs={12}>
            <Button onClick={changeToPaidOrder} variant={'outlined'}>
              Commande payante
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ProductsAutocomplete
              autoFocus={true}
              country={shippingAddress?.country || 'BE'}
              onSelect={handleSelectProduct}
              onlyPackage
              variant={'outlined'}
              withPrice
              withoutRestriction
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={'center'}>
              <Grid item xs={12}>
                <OrderListItems
                  handleRemoveProduct={handleRemoveProduct}
                  items={products}
                  setProductQuantity={setProductQuantity}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography component={'i'}>
              <Trans i18nKey={'pricesExclTaxes'}>
                The prices displayed are taxes excluded
              </Trans>
            </Typography>
          </Grid>
          {shippingAddresses && (
            <Grid item sm={12} xs={12}>
              <Typography component="legend" gutterBottom>
                Livraison
              </Typography>
              <SelectValidator
                fullWidth
                name="shippingAddress"
                onChange={handleChangeShippingAddress}
                required
                value={shippingAddress ? parseInt(shippingAddress.id) : ''}
              >
                {shippingAddresses.map((address) => (
                  <MenuItem key={address.id} value={address.id}>{`${
                    address.byDefault ? '(par défaut) ' : ''
                  }${address.name ? address.name : 'Adresse'}: ${
                    address.address
                  }`}</MenuItem>
                ))}
              </SelectValidator>
            </Grid>
          )}
          {shippingMethods && (
            <Grid item sm={12} xs={12}>
              <Typography component="legend" gutterBottom>
                Méthode de livraison
              </Typography>
              <SelectValidator
                fullWidth
                name="shippingMethod"
                onChange={handleChangeShippingMethod}
                required
                value={shippingMethod ? parseInt(shippingMethod.id) : ''}
              >
                {shippingMethods
                  .filter((shippingMethod) => !shippingMethod.pickupPoint)
                  .map((shippingMethod) => (
                    <MenuItem key={shippingMethod.id} value={shippingMethod.id}>
                      {shippingMethod.name}
                    </MenuItem>
                  ))}
              </SelectValidator>
            </Grid>
          )}
          <Grid item md={6} sm={6} xs={12}>
            <FormControl component="fieldset">
              <Typography component="legend" gutterBottom>
                Transport
              </Typography>
              <RadioGroup
                name="withShipping"
                onChange={handleChangeWithShipping}
                value={withShipping}
              >
                <FormControlLabel
                  control={<Radio />}
                  label="Retirer la commande à la logistique"
                  value={false}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Envoyer la commande"
                  value={true}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
          type="button"
          variant="outlined"
        >
          <Trans i18nKey={'cancelButton'}>Cancel</Trans>
        </Button>
        <Button
          color="primary"
          disabled={
            Object.keys(products).length === 0 ||
            (!shippingMethod && !shippingAddress) ||
            loading
          }
          onClick={handleSubmit}
          type="submit"
          variant="contained"
        >
          {withShipping
            ? 'Créer la commande et la faire livrer'
            : 'Créer la commande et la retirer depuis la logistique'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddFreeUserOrderModal;
