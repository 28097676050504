import React, { createContext, useState, useContext } from 'react';
import AddUserOrderModal from '../AddUserOrder/AddUserOrderModal';
import AddFreeUserOrderModal from '../AddUserOrder/AddFreeUserOrderModal';
import { useHistory } from 'react-router-dom';

const OrderModalContext = createContext();

export const OrderModalProvider = ({ children, user }) => {
  const [isOrderModalOpen, setOrderModalOpen] = useState(false);
  const [isFreeOrderModalOpen, setFreeOrderModalOpen] = useState(false);
  const history = useHistory();

  const openOrderModal = () => {
    setOrderModalOpen(true);
    setFreeOrderModalOpen(false);
  };

  const openFreeOrderModal = () => {
    setFreeOrderModalOpen(true);
    setOrderModalOpen(false);
  };

  const handleRedirect = (orderId) => {
    history.push(`/admin/accounting/order/${orderId}`);
  };

  return (
    <OrderModalContext.Provider
      value={{
        openOrderModal,
        openFreeOrderModal,
      }}
    >
      {children}
      <div>
        {isOrderModalOpen && (
          <AddUserOrderModal
            changeToFreeOrder={openFreeOrderModal}
            handleClose={() => setOrderModalOpen(false)}
            onSuccess={handleRedirect}
            open={isOrderModalOpen}
            user={user}
          />
        )}
        {isFreeOrderModalOpen && (
          <AddFreeUserOrderModal
            changeToPaidOrder={openOrderModal}
            handleClose={() => setFreeOrderModalOpen(false)}
            onSuccess={handleRedirect}
            open={isFreeOrderModalOpen}
            user={user}
          />
        )}
      </div>
    </OrderModalContext.Provider>
  );
};

export const useOrderModal = () => useContext(OrderModalContext);
