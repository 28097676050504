import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import OrderListItems from './OrderListItems';
import Typography from '@mui/material/Typography';
import ProductsAutocomplete from '../../../../Customer/Shop/components/Search/ProductsAutocomplete';
import { userService } from '../../store/services';
import {
  SnackbarConfirm,
  SnackbarError,
  SnackbarFormattedError,
  SnackbarSuccess,
} from '../../../../../helpers/snackbar';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { shopService } from '../../../../Customer/Shop/store/services';
import { shopService as adminShopService } from '../../../../Admin/Shop/store/services';
import { history } from '../../../../../helpers/store';
import SelectValidator from '../../../../../components/Validators/SelectValidator';
import MenuItem from '@mui/material/MenuItem';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';

import DialogActions from '@mui/material/DialogActions';
import {
  CUSTOMER_ROLE,
  PROSPECT_ROLE,
} from '../../../../Authentication/store/constants';

const QuillEditor = lazy(() => import('../../../../../components/Quill'));

const useStyle = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    //minWidth: 400,
    '& .MuiContainer-root': {
      marginTop: 20,
      marginBottom: 20,
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
  addProductButton: {
    marginBottom: 30,
  },
}));

const AddUserOrderModal = ({
  changeToFreeOrder,
  handleClose,
  onSuccess,
  open,
  user,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState({});
  const [displayedNote, setDisplayedNote] = useState('');
  const [shippingMethods, setShippingMethods] = useState(null);
  const [shippingMethod, setShippingMethod] = useState(null);
  const [giftCards, setGiftCards] = useState([]);
  const [giftCard, setGiftCard] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('stripe');
  const [shippingAddresses, setShippingAddresses] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);

  useEffect(() => {
    userService
      .getUserShippingAddresses(user.id)
      .then((shippingAddresses) => {
        setShippingAddresses(shippingAddresses);
        setShippingAddress(shippingAddresses[0]);
      })
      .catch((e) => SnackbarFormattedError(e.error));
    adminShopService
      .getDiscountCodesAvailable(user.id)
      .then((giftCards) => {
        setGiftCards(giftCards);
      })
      .catch((e) => SnackbarFormattedError(e.error));
  }, [user]);

  useEffect(() => {
    if (shippingAddress) {
      shopService
        .getShippingMethods(shippingAddress.country, 1)
        .then((shippingMethods) => {
          setShippingMethods(shippingMethods);
          setShippingMethod(shippingMethods[0]);
        })
        .catch((e) => SnackbarFormattedError(e.error));
    }
  }, [shippingAddress]);

  const handleSelectProduct = (product) => {
    if (!products[product.id]) {
      product.priceAndTaxDetails.final = [
        PROSPECT_ROLE,
        CUSTOMER_ROLE,
      ].includes(user.role)
        ? product.priceAndTaxDetails.base
        : product.priceAndTaxDetails.final;
      setProducts({ ...products, [product.id]: { ...product, quantity: 1 } });
    }
  };

  const handleRemoveProduct = (productId) => {
    let oldProducts = products;
    delete oldProducts[productId];
    setProducts({ ...oldProducts });
  };

  const setProductQuantity = (product, quantity) => {
    if (quantity === 0) {
      handleRemoveProduct(product.id);
      return;
    }
    setProducts({
      ...products,
      [product.id]: { ...product, quantity: quantity },
    });
  };

  const handleChangePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleNoteChange = (value) => {
    setDisplayedNote(value);
  };

  const handleChangeGiftCard = (e) => {
    setGiftCard(e.target.value);
  };

  const handleChangeShippingMethod = (e) => {
    const method = shippingMethods.find((a) => a.id === e.target.value);
    setShippingMethod(method);
    if (method.name === 'withoutDelivery' && paymentMethod === 'stripe') {
      setShippingAddress(null);
      setPaymentMethod(null);
    }
  };

  const handleChangeShippingAddress = (e) => {
    setShippingAddress(shippingAddresses.find((a) => a.id === e.target.value));
  };

  const handleSubmit = () => {
    if (
      !shippingMethod ||
      (!shippingAddress && shippingMethod?.name !== 'withoutDelivery')
    ) {
      SnackbarError(
        'Vous devez séléctionner une méthode de livraison et séléctionner une adresse',
      );
      return;
    }
    SnackbarConfirm('Confirmer la création de la commande ?', () => {
      setLoading(true);
      userService
        .addUserOrder(
          user,
          Object.keys(products).map((productId) => ({
            id: productId,
            quantity: products[productId].quantity,
          })),
          shippingMethod.id,
          shippingAddress?.id,
          paymentMethod,
          giftCard,
          displayedNote,
        )
        .then((order) => {
          setLoading(false);
          setProducts({});
          onSuccess(order.id);
          handleClose();
          if (paymentMethod !== 'wallet') {
            SnackbarSuccess("L'email de paiement a bien été envoyé");
          } else {
            SnackbarConfirm(
              'Aller à la facture ?',
              () => {
                history.push(`/admin/accounting/order/${order.id}`);
              },
              () => {
                SnackbarSuccess('La facture a bien été créée');
              },
            );
          }
        })
        .catch((e) => {
          setLoading(false);
          SnackbarFormattedError(e.error);
        });
    });
  };

  return (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
    >
      <CloseIcon className={classes.closeButton} onClick={handleClose} />
      <DialogTitle id="responsive-dialog-title">Créer une commande</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sx={{ textAlign: 'right' }} xs={12}>
            <Button onClick={changeToFreeOrder} variant={'outlined'}>
              Offrir des echantillons
            </Button>
          </Grid>
          <Grid item key={Object.keys(products).length} xs={12}>
            <ProductsAutocomplete
              autoFocus={true}
              country={shippingAddress?.country || 'BE'}
              onSelect={handleSelectProduct}
              onlyPackage
              variant={'outlined'}
              withPrice
              withoutRestriction
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={'center'}>
              <Grid item xs={12}>
                <OrderListItems
                  handleRemoveProduct={handleRemoveProduct}
                  items={products}
                  setProductQuantity={setProductQuantity}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography className={'label'} gutterBottom>
              {t('noteForTheCustomer')} :
            </Typography>
            <Suspense fallback={<div>Loading...</div>}>
              <QuillEditor
                name={`displayedNote`}
                onChange={handleNoteChange}
                value={displayedNote}
              />
            </Suspense>
          </Grid>
          {shippingAddresses && shippingMethod?.name !== 'withoutDelivery' && (
            <Grid item sm={12} xs={12}>
              <Typography component="legend" gutterBottom>
                Livraison
              </Typography>
              <SelectValidator
                fullWidth
                name="shippingAddress"
                onChange={handleChangeShippingAddress}
                required
                value={shippingAddress ? parseInt(shippingAddress.id) : ''}
              >
                {shippingAddresses.map((address) => (
                  <MenuItem key={address.id} value={address.id}>{`${
                    address.byDefault ? '(par défaut) ' : ''
                  }${address.name ? address.name : 'Adresse'}: ${
                    address.address
                  }`}</MenuItem>
                ))}
              </SelectValidator>
            </Grid>
          )}
          {shippingMethods && (
            <Grid item sm={12} xs={12}>
              <Typography component="legend" gutterBottom>
                Méthode de livraison
              </Typography>
              <SelectValidator
                fullWidth
                name="shippingMethod"
                onChange={handleChangeShippingMethod}
                required
                value={shippingMethod ? parseInt(shippingMethod.id) : ''}
              >
                {shippingMethods
                  .filter((shippingMethod) => !shippingMethod.pickupPoint)
                  .map((shippingMethod) => (
                    <MenuItem key={shippingMethod.id} value={shippingMethod.id}>
                      {t(shippingMethod.name)}
                    </MenuItem>
                  ))}
              </SelectValidator>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <Typography component="legend" gutterBottom>
                Paiement
              </Typography>
              <RadioGroup
                name="paymentMethod"
                onChange={handleChangePaymentMethod}
                value={paymentMethod}
              >
                <FormControlLabel
                  control={<Radio />}
                  label="Demander un virement bancaire par email"
                  value={'bankTransfer'}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Encaisser la commande avec des espèces et créer la facture"
                  value={'cash'}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
          type="button"
          variant="outlined"
        >
          <Trans i18nKey={'cancelButton'}>Cancel</Trans>
        </Button>
        <Button
          color="primary"
          disabled={
            Object.keys(products).length === 0 ||
            loading ||
            !shippingMethod ||
            (!shippingAddress && shippingMethod?.name !== 'withoutDelivery') ||
            !paymentMethod
          }
          onClick={handleSubmit}
          type="submit"
          variant="contained"
        >
          Créer la commande
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddUserOrderModal;
